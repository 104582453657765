import React from "react";
import { connect } from "react-redux";
import { ReactComponent as HorizontalAlignmentLeft } from "assets/icons/common/horizontal-alignment-left.svg";
import { ReactComponent as HorizontalAlignmentMiddle } from "assets/icons/common/horizontal-alignment-middle.svg";
import { ReactComponent as HorizontalAlignmentRight } from "assets/icons/common/horizontal-alignment-right.svg";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  WidgetType,
  ImageAlign,
  CanvasLayout,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { type AppState } from "store/types";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { sizeSection, visibilitySection } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { isActionHandlerSet } from "../shared";
import withMeta from "../withMeta";
import ImageComponent from "./ImageComponent";

export default class ImageWidget extends BaseWidget<
  ImageWidgetProps,
  WidgetState
> {
  constructor(props: ImageWidgetProps) {
    super(props);
    this.onImageClick = this.onImageClick.bind(this);
  }
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: (
              <>
                Renders the image from the URL or Data URL in the component.{" "}
                <a
                  href="https://docs.superblocks.com/applications/components-library/image#display-an-image"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more in the docs →
                </a>
              </>
            ),
            propertyName: "image",
            label: "Image",
            controlType: "INPUT_TEXT",
            placeholderText: "URL or Data URL",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "backgroundColor",
            label: "Background",
            helpText: "Changes the color of the background",
            controlType: "COLOR_PICKER",
            themeValue: "transparent", // TODO: make removable
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "borderRadius",
            label: "Border radius",
            placeholderText: "15px or 50%",
            controlType: "INPUT_TEXT",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "fillContainer",
            label: "Fill the container",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            propertyName: "align",
            helpText: "Sets the alignment of the image",
            label: "Align",
            controlType: "RADIO_BUTTON_GROUP",
            options: [
              {
                icon: <HorizontalAlignmentLeft />,
                value: ImageAlign.LEFT,
              },
              {
                icon: <HorizontalAlignmentMiddle />,
                value: ImageAlign.CENTER,
              },
              {
                icon: <HorizontalAlignmentRight />,
                value: ImageAlign.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
            hidden: (props) => !props.fillContainer,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
        ],
      },
      sizeSection({
        heightSupportsFitContent: true,
        widthSupportsFitContent: true,
      }),
      visibilitySection({ useJsExpr: false }),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onClick",
            "Triggers an action when a user clicks the image",
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      image: VALIDATION_TYPES.TEXT,
      imageShape: VALIDATION_TYPES.TEXT,
      defaultImage: VALIDATION_TYPES.TEXT,
      maxZoomLevel: VALIDATION_TYPES.NUMBER,
      borderRadius: VALIDATION_TYPES.TEXT,
      fillContainer: VALIDATION_TYPES.BOOLEAN,
    };
  }
  getPageView() {
    return (
      <ImageComponent
        disableDrag={(disable: boolean) => {
          this.disableDrag(disable);
        }}
        widgetId={this.props.widgetId}
        imageUrl={this.props.image || ""}
        onClick={
          isActionHandlerSet(this.props.onClick) ? this.onImageClick : undefined
        }
        align={this.props.align}
        showHoverPointer={
          this.props.appMode === APP_MODE.PUBLISHED ||
          this.props.appMode === APP_MODE.PREVIEW
        }
        defaultImageUrl={this.props.defaultImage}
        isLoading={this.props.isLoading}
        borderRadius={this.props.borderRadius}
        fillContainer={this.props.fillContainer}
        backgroundColor={this.props.backgroundColor}
        roundHeightToGrid={
          (this.props.parentLayout ?? CanvasLayout.FIXED) === CanvasLayout.FIXED
        }
        height={this.props.height}
        width={this.props.width}
      />
    );
  }

  onImageClick() {
    if (this.props.onClick) {
      super.runEventHandlers({
        steps: this.props.onClick,
        type: EventType.ON_CLICK,
        additionalNamedArguments: {},
      });
    }
  }

  getWidgetType(): WidgetType {
    return "IMAGE_WIDGET";
  }
}

type ImageShape = "RECTANGLE" | "CIRCLE" | "ROUNDED";

export interface ImageWidgetProps extends WidgetPropsRuntime {
  image: string;
  backgroundColor?: string;
  imageShape: ImageShape;
  defaultImage: string;
  maxZoomLevel: number;
  onClick?: MultiStepDef;
  align?: ImageAlign;
  borderRadius: string;
  fillContainer: boolean;
  isVisible?: boolean;
}

const mapStateToProps = (state: AppState, ownProps: ImageWidgetProps) => {
  return {
    // overwrite onClick from ownProps with the one from redux
    // TODO: investigate why ownProps.onClick doesn't match with data received from DSL (ex. inside Grid images)
    onClick:
      getWidgetPropertiesById(state, ownProps.widgetId)?.onClick ||
      ownProps.onClick,
  };
};

export const ConnectedImageWidget = connect(mapStateToProps)(
  withMeta(ImageWidget),
);
