import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Skeleton from "legacy/components/utils/Skeleton";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { retryPromise } from "legacy/utils/Utils";
import { DiffMethod } from "legacy/widgets/DiffWidget/DiffMethod";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import withMeta, { WithMeta } from "../withMeta";
import { derived } from "./derived";
import type { AppState } from "store/types";

const ReactDiffViewer = lazy(() =>
  retryPromise(
    () =>
      import(
        /* webpackChunkName: "reactDiff" */ "legacy/widgets/DiffWidget/DiffViewer"
      ),
  ),
);

const StyledWrapper = styled.div<{
  height: number;
}>`
  height: ${(props) => props.height}px;
  font-family: monospace;

  && pre {
    line-height: 16px;
  }

  overflow-y: auto;
  overflow-x: hidden;
`;

class DiffWidget extends BaseWidget<DiffWidgetProps, DiffWidgetState> {
  constructor(props: DiffWidgetProps) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "compareMethod",
            label: "Compare by",
            controlType: "DROP_DOWN",
            helpText: "Method used for diffing strings",
            options: [
              { value: DiffMethod.LINES, label: "Line" },
              { value: DiffMethod.WORDS, label: "Word" },
              { value: DiffMethod.CHARS, label: "Character" },
              { value: DiffMethod.CSS, label: "CSS" },
              { value: DiffMethod.JSON, label: "JSON" },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "originalLabel",
            label: "Original label",
            helpText: "Label for old value",
            controlType: "INPUT_TEXT",
            placeholderText: "Original",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Original",
          },
          {
            propertyName: "originalText",
            label: "Original text",
            helpText: "Left side text to compare",
            controlType: "INPUT_TEXT",
            placeholderText: "{ 'file': 'text.txt' }",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "newLabel",
            label: "New label",
            helpText: "Label for new value",
            controlType: "INPUT_TEXT",
            placeholderText: "New",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "New",
          },
          {
            propertyName: "newText",
            label: "New text",
            helpText: "Right Side text to compare",
            controlType: "INPUT_TEXT",
            placeholderText: "{ 'file': 'text.json' }",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
          },
        ],
      },
      sizeSection(),
      {
        sectionName: "Advanced",
        children: [
          {
            propertyName: "showDiffOnly",
            label: "Compact view",
            controlType: "SWITCH",
            helpText:
              "Shows only the diffed lines and folds the unchanged lines",
            validation: VALIDATION_TYPES.BOOLEAN,
            isJSConvertible: false,
            isBindProperty: false,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      originalText: VALIDATION_TYPES.TEXT,
      newText: VALIDATION_TYPES.TEXT,
    };
  }

  static getDerivedPropertiesMap() {
    return {
      diffs: `{{(${derived.diffs})()}}`,
    };
  }

  getPageView() {
    const { componentHeight } = getComponentDimensions(this.props);
    return (
      <Suspense fallback={<Skeleton />}>
        <StyledWrapper
          height={componentHeight}
          className={CLASS_NAMES.DEFAULT_CONTAINER}
          // manual override to match the diff viewer's built-in dark mode
          // in the future, we shuold update the diff viewer to conform to our theme
          style={
            this.props.generatedTheme.mode === "DARK"
              ? { backgroundColor: "#2f323e" }
              : undefined
          }
        >
          {this.props.isLoading ? (
            <Skeleton />
          ) : (
            <ReactDiffViewer
              leftTitle={this.props.originalLabel}
              rightTitle={this.props.newLabel}
              oldValue={
                typeof this.props.originalText === "string"
                  ? this.props.originalText
                  : JSON.stringify(this.props.newText)
              }
              newValue={
                typeof this.props.newText === "string"
                  ? this.props.newText
                  : JSON.stringify(this.props.newText)
              }
              showDiffOnly={this.props.showDiffOnly}
              extraLinesSurroundingDiff={2}
              splitView={true}
              compareMethod={this.props.compareMethod}
              useDarkTheme={this.props.generatedTheme.mode === "DARK"}
            />
          )}
        </StyledWrapper>
      </Suspense>
    );
  }

  getWidgetType(): WidgetType {
    return WidgetTypes.DIFF_WIDGET;
  }
}

export interface DiffWidgetProps extends WidgetPropsRuntime, WithMeta {
  originalLabel?: string;
  originalText?: string;
  newLabel?: string;
  newText?: string;
  compareMethod?: DiffMethod;
  showDiffOnly?: boolean;
  isVisible?: boolean;
  generatedTheme: GeneratedTheme;
}

interface DiffWidgetState extends WidgetState {
  isLoading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  generatedTheme: selectGeneratedTheme(state),
});

export default DiffWidget;
export const ConnectedDiffWidget = connect(mapStateToProps)(
  withMeta(DiffWidget),
);
