import React from "react";
import { connect } from "react-redux";
import { EventType, MultiStepDef } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { type AppState } from "store/types";

import { getComponentDimensions } from "utils/size";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "../BaseWidget";
import { isFitContent } from "../base/sizing";
import { sizeSection, visibilitySection } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { isActionHandlerSet } from "../shared";
import withMeta from "../withMeta";
import IconComponent from "./IconComponent";

export default class IconWidget extends BaseWidget<
  IconWidgetProps,
  WidgetState
> {
  constructor(props: IconWidgetProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "icon",
            label: "Icon",
            helpText: "Select an icon",
            controlType: "ICON_SELECTOR",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            canExpandEditor: true,
            customJSControl: "CODE_EDITOR_ICON_SELECTOR",
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            propertyName: "color",
            label: "Color",
            helpText: "Changes the color of the icon",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            themeValue: "colors.neutral700",
            propertyCategory: PropsPanelCategory.Appearance,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
        heightSupportsFitContent: true,
      }),
      visibilitySection({ useJsExpr: false }),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onClick",
            "Triggers an action when a user clicks the image",
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      icon: VALIDATION_TYPES.ICONS,
      color: VALIDATION_TYPES.TEXT,
    };
  }
  getPageView() {
    const { componentWidth, componentHeight } = getComponentDimensions(
      this.props,
    );
    const isAutoWidth = isFitContent(this.props.width.mode);
    const isAutoHeight = isFitContent(this.props.height.mode);
    let size = Math.min(componentWidth, componentHeight);

    if (isAutoWidth && !isAutoHeight) {
      size = componentHeight;
    } else if (!isAutoWidth && isAutoHeight) {
      size = componentWidth;
    }
    size = size - WIDGET_PADDING * 2;

    return (
      <IconComponent
        widgetId={this.props.widgetId}
        icon={this.props.icon}
        color={this.props.color}
        onClick={
          isActionHandlerSet(this.props.onClick) ? this.onClick : undefined
        }
        isLoading={this.props.animateLoading && this.props.isLoading}
        size={size}
      />
    );
  }
  onClick() {
    if (this.props.onClick) {
      super.runEventHandlers({
        steps: this.props.onClick,
        type: EventType.ON_CLICK,
        additionalNamedArguments: {},
      });
    }
  }

  getWidgetType(): WidgetType {
    return "ICON_WIDGET";
  }
}

export interface IconWidgetProps extends WidgetPropsRuntime {
  icon: string;
  color: string;
  onClick?: MultiStepDef;
  isVisible?: boolean;
  animateLoading: boolean;
}

const mapStateToProps = (state: AppState, ownProps: IconWidgetProps) => {
  return {
    // overwrite onClick from ownProps with the one from redux
    // TODO: investigate why ownProps.onClick doesn't match with data received from DSL (ex. inside Grid images)
    onClick:
      getWidgetPropertiesById(state, ownProps.widgetId)?.onClick ||
      ownProps.onClick,
  };
};

export const ConnectedIconWidget = connect(mapStateToProps)(
  withMeta(IconWidget),
);
