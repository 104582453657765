import { ApplicationScope, WidgetTypes } from "@superblocksteam/shared";
import React from "react";
import { ReactComponent as LayoutHorizontalIcon } from "assets/icons/common/layout-horizontal.svg";
import { ReactComponent as LayoutVerticalIcon } from "assets/icons/common/layout-vertical.svg";
import { EventType } from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import { createRunEventHandlersPayloadOptional } from "legacy/utils/actions";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import BaseWidget, { WidgetState } from "../BaseWidget";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { updateHeightOnAddOrRemove } from "../propsPanelUtils";
import { typographyProperties } from "../styleProperties";
import withMeta from "../withMeta";
import { RadioGroupComponentWithLayoutManaged } from "./RadioGroupComponent";
import {
  DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
} from "./constants";
import { RadioGroupWidgetProps } from "./types";

class RadioGroupWidget extends BaseWidget<RadioGroupWidgetProps, WidgetState> {
  static getPropertyPaneConfig(): PropertyPaneConfig<RadioGroupWidgetProps>[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Text to be shown above the radio group",
            propertyName: "label",
            label: "Label",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter placeholder text",
            isBindProperty: true,
            isTriggerProperty: false,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "Label",
            updateHook: updateHeightOnAddOrRemove,
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            helpText:
              "Displays a list of options for a user to select. Values must be unique",
            propertyName: "options",
            label: "Options",
            controlType: "OPTION_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            headerControlType: "ADD_OPTION",
            propertyCategory: PropsPanelCategory.Content,
          },
          {
            label: "Orientation",
            propertyName: "isVertical",
            helpText: "Display label and options horizontally or vertically",
            controlType: "RADIO_BUTTON_GROUP",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hideLabelsForUnselected: true,
            propertyCategory: PropsPanelCategory.Appearance,
            options: [
              {
                value: true,
                label: "Vertical",
                icon: <LayoutVerticalIcon />,
              },
              {
                value: false,
                label: "Horizontal",
                icon: <LayoutHorizontalIcon />,
              },
            ],
          },
          ...typographyProperties({
            defaultVariant: DEFAULT_RADIO_GROUP_WIDGET_LABEL_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "labelProps",
            propertyNameForHumans: "Label",
            hiddenIfPropertyNameIsNullOrFalse: "label",
          }),
          ...typographyProperties({
            defaultVariant:
              DEFAULT_RADIO_GROUP_WIDGET_OPTION_TEXT_STYLE_VARIANT,
            textStyleParentDottedPath: "optionProps",
            propertyNameForHumans: "Option",
          }),
        ],
      },
      sizeSection({ heightSupportsFitContent: true }),
      {
        sectionName: "Advanced",
        children: [
          {
            helpText: "Selects a value of the options entered by default",
            propertyName: "defaultOptionValue",
            label: "Default selected value",
            placeholderText: "Enter option value",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Content,
            visibility: "SHOW_NAME",
            isRemovable: true,
            defaultValue: "",
          },
          {
            propertyName: "isRequired",
            label: "Required",
            helpText: "Makes input to the component mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            propertyName: "isDisabled",
            label: "Disabled",
            helpText: "Disables input to this component",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Interaction,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            propertyCategory: PropsPanelCategory.Appearance,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onSelectionChange",
            "Triggers an action when a user changes the selected option",
          ),
        ],
      },
    ];
  }
  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      label: VALIDATION_TYPES.TEXT,
      options: VALIDATION_TYPES.OPTIONS_DATA,
      selectedOptionValue: VALIDATION_TYPES.TEXT,
      defaultOptionValue: VALIDATION_TYPES.TEXT_EMPTY_NULL,
      isRequired: VALIDATION_TYPES.BOOLEAN,
      isDisabled: VALIDATION_TYPES.BOOLEAN,
    };
  }
  static getDerivedPropertiesMap() {
    return {
      selectedOption:
        "{{_.find(this.options, { value: this.selectedOptionValue })}}",
      isValid: `{{ this.isRequired ? !!this.selectedOptionValue : true }}`,
      value: `{{this.selectedOptionValue}}`,
    };
  }
  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedOptionValue: "defaultOptionValue",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptionValue: undefined,
    };
  }

  getPageView() {
    return (
      <RadioGroupComponentWithLayoutManaged
        {...this.props}
        onRadioSelectionChange={this.onRadioSelectionChange}
      />
    );
  }

  onRadioSelectionChange = (updatedValue: string) => {
    this.props.updateWidgetMetaProperty(
      "selectedOptionValue",
      updatedValue,
      createRunEventHandlersPayloadOptional({
        steps: this.props.onSelectionChange,
        currentScope: ApplicationScope.PAGE,
        type: EventType.ON_OPTION_CHANGE,
        entityName: this.props.widgetName,
      }),
    );
  };

  getWidgetType(): WidgetType {
    return WidgetTypes.RADIO_GROUP_WIDGET;
  }
}

export default RadioGroupWidget;
export const ConnectedRadioGroupWidget = withMeta(RadioGroupWidget);
