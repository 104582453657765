import React from "react";
import {
  EventType,
  ExecutionResult,
  MultiStepDef,
} from "legacy/constants/ActionConstants";
import {
  PropsPanelCategory,
  PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import {
  BASE_WIDGET_VALIDATION,
  WidgetPropertyValidationType,
} from "legacy/constants/WidgetValidation";

import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import BaseWidget, { WidgetPropsRuntime, WidgetState } from "./BaseWidget";
import ButtonComponent, {
  StaticTypographyButtonProps,
} from "./ButtonWidget/ButtonComponent";
import { sizeSection, visibleProperties } from "./basePropertySections";
import { getPopoverConfig } from "./eventHandlerPanel";
import withMeta, { WithMeta } from "./withMeta";

class FormButtonWidget extends BaseWidget<
  FormButtonWidgetProps,
  FormButtonWidgetState
> {
  onButtonClickBound: (event: React.MouseEvent<HTMLElement>) => void;

  constructor(props: FormButtonWidgetProps) {
    super(props);
    this.onButtonClickBound = this.onButtonClick.bind(this);
    this.state = {
      isLoading: false,
    };
  }

  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "text",
            label: "Label",
            helpText: "Sets the label of the button",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            propertyName: "buttonStyle",
            label: "Button style",
            helpText: "Changes the style of the button",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Primary button",
                value: "PRIMARY_BUTTON",
              },
              {
                label: "Secondary button",
                value: "SECONDARY_BUTTON",
              },
              {
                label: "Danger button",
                value: "DANGER_BUTTON",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText:
              "Disables the button when the parent form has a required component that is not filled",
            propertyName: "disabledWhenInvalid",
            label: "Disabled invalid forms",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText:
              "Resets the fields within the parent form when the click action succeeds",
            propertyName: "resetFormOnClick",
            label: "Reset Form on Success",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          {
            helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
            propertyName: "animateLoading",
            label: "Loading animation",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
          },
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
      sizeSection({
        widthSupportsFitContent: true,
      }),
      {
        sectionName: "Actions",
        sectionCategory: PropsPanelCategory.EventHandlers,
        children: [
          getPopoverConfig(
            "onClick",
            "Triggers an action when the button is clicked",
          ),
        ],
      },
    ];
  }

  static getPropertyValidationMap(): WidgetPropertyValidationType {
    return {
      ...BASE_WIDGET_VALIDATION,
      text: VALIDATION_TYPES.TEXT,
      disabledWhenInvalid: VALIDATION_TYPES.BOOLEAN,
      buttonStyle: VALIDATION_TYPES.TEXT,
    };
  }

  onButtonClick() {
    if (this.props.onClick && !this.state.isLoading) {
      this.setState({
        isLoading: true,
      });
      const callbackId = addNewPromise(this.handleActionResult);
      super.runEventHandlers({
        steps: this.props.onClick,
        type: EventType.ON_CLICK,
        callbackId,
        additionalNamedArguments: {},
      });
    } else if (this.props.resetFormOnClick && this.props.onReset) {
      this.props.onReset();
    }
  }

  handleActionResult = (result: ExecutionResult) => {
    this.setState({
      isLoading: false,
    });
    if (result.success) {
      if (this.props.resetFormOnClick && this.props.onReset)
        this.props.onReset();
    }
  };

  getPageView() {
    const disabled =
      this.props.disabledWhenInvalid &&
      "isFormValid" in this.props &&
      !this.props.isFormValid;

    return (
      <ButtonComponent
        buttonStyle={this.props.buttonStyle}
        key={this.props.widgetId}
        text={this.props.text}
        disabled={disabled}
        onClick={!disabled ? this.onButtonClickBound : undefined}
        isLoading={this.props.isLoading || this.state.isLoading}
        ddogActionName="form-button-widget"
        textProps={StaticTypographyButtonProps}
      />
    );
  }

  getWidgetType(): WidgetType {
    return "FORM_BUTTON_WIDGET";
  }
}

type ButtonStyle =
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "SUCCESS_BUTTON"
  | "DANGER_BUTTON";

export interface FormButtonWidgetProps extends WidgetPropsRuntime, WithMeta {
  text?: string;
  buttonStyle?: ButtonStyle;
  onClick?: MultiStepDef;
  isVisible?: boolean;
  isFormValid?: boolean;
  resetFormOnClick?: boolean;
  onReset?: () => void;
  disabledWhenInvalid?: boolean;
}

interface FormButtonWidgetState extends WidgetState {
  isLoading: boolean;
}

export default FormButtonWidget;
export const ConnectedFormButtonWidget = withMeta(FormButtonWidget);
