import { Classes as BlueprintClasses } from "@blueprintjs/core";
import { css } from "styled-components";
import { getCaretIconEncodedUrl } from "components/ui/IconButtons/ChevronDownEncodedUrl";
import { generatePaddingCss } from "legacy/widgets/base/generatePaddingStyle";
import { CLASS_NAMES } from "../classnames";
import { BUTTON_PADDING } from "../constants";
import { GeneratedTheme } from "../types";
import {
  styleFromStyleBlock,
  disabledStyles,
  iconStyleFromStyleBlock,
  menuItemStyleFromBlock,
  inputStyleFromStyleBlock,
  hoverStyles,
  activeStyles,
  defaultStyles,
} from "./utils";

export const generateDropdownStyles = (theme: GeneratedTheme) => {
  const dropdownStyles = theme.dropdown;
  return css`
    .${CLASS_NAMES.TAG_INPUT} {
      .${BlueprintClasses.TAG}.${BlueprintClasses.MINIMAL}.${BlueprintClasses.INTERACTIVE} {
        ${styleFromStyleBlock(dropdownStyles.tag)}
      }
      &.${CLASS_NAMES.DISABLED_MODIFIER} {
        .${BlueprintClasses.TAG}.${BlueprintClasses.MINIMAL} {
          ${disabledStyles(dropdownStyles.tag)}
        }
      }
    }
    .${CLASS_NAMES.DROPDOWN_CLEAR_ICON} {
      ${iconStyleFromStyleBlock(dropdownStyles.closeIcon)}
    }

    .${CLASS_NAMES.CARET_ICON}.${BlueprintClasses.ACTIVE}::before {
      content: url("${getCaretIconEncodedUrl(
        dropdownStyles.caratIcon.iconColor.active ??
          dropdownStyles.caratIcon.iconColor.default,
      )}");
    }
    .${CLASS_NAMES.CARET_ICON}::before {
      content: url("${getCaretIconEncodedUrl(
        dropdownStyles.caratIcon.iconColor.default,
      )}");
    }

    .${BlueprintClasses.POPOVER} .${BlueprintClasses.POPOVER_CONTENT} {
      border-radius: unset;
      background: none;
    }
    .${BlueprintClasses.MENU} {
      background: unset;
      color: unset;
      border-radius: unset;
    }

    .${CLASS_NAMES.DROPDOWN}
      .${BlueprintClasses.POPOVER_CONTENT}
      .${BlueprintClasses.MENU}
      .${BlueprintClasses.MENU_ITEM} {
      ${menuItemStyleFromBlock(dropdownStyles.menuItem)}
    }

    .${CLASS_NAMES.MENU_ITEM} {
      ${menuItemStyleFromBlock(dropdownStyles.menuItem)}
    }

    .${CLASS_NAMES.DROPDOWN}.ant-select {
      .ant-select-selection-item {
        ${defaultStyles(theme.inputs.input)}
      }
      .ant-select-selector {
        ${inputStyleFromStyleBlock(theme.inputs.input)}
        ${generatePaddingCss(BUTTON_PADDING)};
      }
      &:hover {
        .ant-select-selector {
          ${hoverStyles(theme.inputs.input)}
        }
      }
      &:focus,
      &:active,
      &.ant-select-focused {
        .ant-select-selector:not(.ant-select-disabled) {
          ${activeStyles(theme.inputs.input)}
        }
      }
    }
  `.join("");
};
