import equal from "@superblocksteam/fast-deep-equal/es6";
import { some } from "lodash";
import React from "react";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";

import { fastClone } from "utils/clone";
import { WidgetPropsRuntime } from "./BaseWidget";
import ContainerWidget, { ContainerWidgetProps } from "./ContainerWidget";
import { WidgetLayoutProps } from "./shared";
import withChildren, { WithChildrenProps } from "./withChildren";
import withMeta, { WithMeta } from "./withMeta";

const includeWidgetInValidation = (child: {
  type: WidgetType;
  isVisible?: boolean;
}) => {
  if (child.isVisible === false) return false;
  return includeWidgetInFormData(child);
};

const includeWidgetInFormData = (child: { type: WidgetType }) => {
  return !(
    [
      WidgetTypes.SLIDEOUT_WIDGET, // Detached so should not count
      WidgetTypes.MODAL_WIDGET, // Detached so should not count
      WidgetTypes.GRID_WIDGET, // Not trusted, ignore for now
      WidgetTypes.FORM_WIDGET, // We think nested forms should not contribute to the form data
    ] as WidgetType[]
  ).includes(child.type);
};

class FormWidget extends ContainerWidget<FormWidgetProps> {
  static getMetaPropertiesMap(): Record<string, unknown> {
    return {
      data: undefined,
    };
  }

  checkInvalidChildren = (children: WidgetPropsRuntime[]): boolean => {
    return some(
      children,
      (child: WidgetPropsRuntime & { isValid?: boolean }) => {
        if (!includeWidgetInValidation(child)) return false;

        if (child.children) {
          return this.checkInvalidChildren(child.children);
        }
        if ("isValid" in child) {
          return !child.isValid;
        }
        return false;
      },
    );
  };

  getFormData(children: WidgetPropsRuntime[]) {
    const formData: Record<string, any> = {};
    if (children)
      children.forEach((widgetData: WidgetPropsRuntime) => {
        if (!includeWidgetInFormData(widgetData)) return formData;

        if (widgetData.children) {
          const nestedData = this.getFormData(widgetData.children);
          Object.keys(nestedData).forEach((key) => {
            formData[key] = fastClone(nestedData[key]);
          });
        }
        if ("value" in widgetData) {
          formData[widgetData.widgetName] = fastClone(widgetData.value);
        }
      });
    return formData;
  }

  handleResetInputs = () => {
    super.resetChildrenMetaProperty(this.props.widgetId);
  };

  componentDidMount() {
    super.componentDidMount();
    this.updateFormData();
  }

  componentDidUpdate(prevProps: FormWidgetProps) {
    super.componentDidUpdate(prevProps);
    this.updateFormData();
  }

  updateFormData() {
    if (this.props.childWidgets) {
      const data = this.getFormData(this.props.childWidgets);
      const isValid = !this.checkInvalidChildren(this.props.childWidgets);
      if (isValid !== this.props.isValid || !equal(data, this.props.data)) {
        this.context.updateWidgetMetaProperties?.(this.props.widgetId, {
          data,
          isValid,
        });
      }
    }
  }

  renderChildWidget(childWidgetData: WidgetLayoutProps): React.ReactNode {
    if (childWidgetData.children) {
      type GradChildProps = WidgetLayoutProps & {
        isFormValid?: boolean;
        onReset?: () => void;
      };

      const grandChildren = childWidgetData.children.map(
        (grandChild: GradChildProps) => {
          if (grandChild.type === WidgetTypes.FORM_BUTTON_WIDGET) {
            return {
              ...grandChild,
              isFormValid: this.props.isValid,
              onReset: this.handleResetInputs,
            };
          }
          return grandChild;
        },
      );
      return super.renderChildWidget({
        ...childWidgetData,
        children: grandChildren,
      });
    } else {
      return super.renderChildWidget(childWidgetData);
    }
  }

  getWidgetType(): WidgetType {
    return "FORM_WIDGET";
  }
}

export type FormWidgetProps = ContainerWidgetProps<WidgetPropsRuntime> &
  WithChildrenProps &
  WithMeta & {
    name: string;
    data: Record<string, unknown>;
    isValid: boolean;
  };

export default FormWidget;
export const ConnectedFormWidget = withChildren(withMeta(FormWidget));
