import { Dimension } from "@superblocksteam/shared";
import React from "react";
import { select } from "redux-saga/effects";
import {
  UpdateWidgetPropertiesPayload,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { DropDownControlOption } from "legacy/components/propertyControls/DropDownControl";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { Breakpoints, WidgetTypes } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { APP_MODE } from "legacy/reducers/types";
import { updateSectionWidgetCanvasHeights } from "legacy/sagas/WidgetOperationsSagasUtils";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import {
  DEFAULT_CONTAINER_BORDER_OBJECT,
  NO_BORDER_OBJECT,
} from "legacy/themes/constants";
import { AllFlags } from "store/slices/featureFlags";
import { WidgetPropsRuntime } from "../BaseWidget";
import ContainerWidget, { ContainerWidgetProps } from "../ContainerWidget";
import {
  paddingProperty,
  sizeSection,
  visibleProperties,
} from "../basePropertySections";
import { backgroundColorProperty, borderProperty } from "../styleProperties";
import SectionComponent from "./SectionComponent";
import type {
  CanvasWidgetsReduxState,
  WidgetActionHook,
  WidgetActionResponse,
} from "../Factory";

const sectionBackgroundHidden =
  (parentWidgetTypes: Array<WidgetTypes>) =>
  (
    props: any,
    propertyPath: string,
    featureFlags: Partial<AllFlags>,
    additionalHiddenData?: Record<string, any>,
  ) => {
    const parentType =
      additionalHiddenData?.canvasWidgets?.[props.parentId]?.type;
    if (parentType == null) {
      return true;
    }

    return !parentWidgetTypes.includes(parentType);
  };
class SectionWidget extends ContainerWidget {
  static getPropertyPaneConfig(): PropertyPaneConfig[] {
    return [
      {
        sectionName: "Sections",
        sectionCategory: PropsPanelCategory.Content,
        children: [
          {
            propertyName: "columns",
            label: "Columns",
            controlType: "CHILD_LIST",
            showColumnRatio: true,
            headerControlType: "ADD_SECTION_COLUMN",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      sizeSection({
        heightSupportsFitContent: true,
        hideMinMaxWidthSection: true,
      }),
      {
        sectionName: "Style",
        sectionCategory: PropsPanelCategory.Layout,
        children: [
          {
            propertyName: "position",
            label: "Sticky",
            helpText:
              "Controls whether the section is sticky to the top of the Page. When enabled, sections below this one will scroll under the section.",
            controlType: "STICKY_POSITION",
            defaultValue: undefined,
            isJSConvertible: false,
            isBindProperty: false,
            isTriggerProperty: false,
            canExpandEditor: true,
            customJSControl: "INPUT_JS_EXPR",
            validation: VALIDATION_TYPES.BOOLEAN,
          },
          {
            propertyName: "stackColumnsAt",
            label: "Stack columns at",
            helpText:
              "Convert horizontal columns to vertical at what device size",
            controlType: "DROP_DOWN",
            optionsFunc: (props) => {
              const help = {
                warning: {
                  message:
                    "This option is not available when height is set to Fill Viewport",
                },
              };

              return [
                {
                  label: "Never",
                  value: undefined,
                },
                {
                  label: `Tablet & Mobile (${Breakpoints.TABLET.value}${Breakpoints.TABLET.mode})`,
                  value: Breakpoints.TABLET,
                  disabled: props.height.mode === "fillParent",
                  help: props.height.mode === "fillParent" ? help : undefined,
                },
                {
                  label: `Mobile (${Breakpoints.MOBILE.value}${Breakpoints.MOBILE.mode})`,
                  value: Breakpoints.MOBILE,
                  disabled: props.height.mode === "fillParent",
                  help: props.height.mode === "fillParent" ? help : undefined,
                },
              ] satisfies DropDownControlOption[];
            },
            optionValueToKey(value: Dimension<"px">) {
              return value ? `${value.value}-${value.mode}` : "undefined";
            },
            isBindProperty: false,
            isTriggerProperty: false,
          },
          backgroundColorProperty({
            defaultColor: "colors.appBackground",
            getAdditionalHiddenData: {
              canvasWidgets: getCanvasWidgets,
            },
            hidden: sectionBackgroundHidden([WidgetTypes.PAGE_WIDGET]),
          }),
          backgroundColorProperty({
            defaultColor: "transparent",
            getAdditionalHiddenData: {
              canvasWidgets: getCanvasWidgets,
            },
            hidden: sectionBackgroundHidden([
              WidgetTypes.MODAL_WIDGET,
              WidgetTypes.SLIDEOUT_WIDGET,
            ]),
          }),
          borderProperty({
            themeValue: NO_BORDER_OBJECT,
            defaultValue: DEFAULT_CONTAINER_BORDER_OBJECT,
          }),
          paddingProperty(),
          ...visibleProperties({ useJsExpr: false }),
        ],
      },
    ];
  }

  getWidgetType = () => {
    return WidgetTypes.SECTION_WIDGET;
  };

  getPageView() {
    return (
      <SectionComponent
        {...this.props}
        renderChildWidget={this.renderChildWidget}
        renderChildren={this.renderChildren}
      />
    );
  }

  makeInvisibleButSpaced() {
    return (
      <SectionComponent
        {...this.props}
        renderChildWidget={() => null}
        renderChildren={() => null}
      />
    );
  }

  static applyActionHook: WidgetActionHook = function* (params: {
    widgetId: string;
    widgets: Readonly<CanvasWidgetsReduxState>;
    action: ReduxAction<UpdateWidgetPropertiesPayload>;
  }): Generator<any, any, any> {
    const { widgetId, widgets, action } = params;
    if (widgets[widgetId].type !== WidgetTypes.SECTION_WIDGET) {
      return;
    }

    const updates: WidgetActionResponse<SectionWidgetProps> = [];
    const widget = widgets[widgetId] as unknown as Readonly<SectionWidgetProps>;

    switch (action.type) {
      case updateWidgetProperties.type: {
        if (
          !updateWidgetProperties.match(action) ||
          action.payload.widgetId !== widgetId
        ) {
          return;
        }

        const heightModeUpdate = action.payload.updates["height.mode"];

        if (heightModeUpdate && heightModeUpdate === "fitContent") {
          const appMode = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
          const theme = yield select(selectGeneratedTheme);

          updateSectionWidgetCanvasHeights(widgets, theme, appMode, widget);
        }

        if (heightModeUpdate && heightModeUpdate === "fillParent") {
          updates.push({
            widgetId,
            widget: {
              ...widget,
              stackColumnsAt: undefined,
            },
          });
        }

        break;
      }
      default:
        break;
    }
    return updates;
  };
}

export interface SectionWidgetProps
  extends ContainerWidgetProps,
    WidgetPropsRuntime {
  isVisible?: boolean;
  children?: ContainerWidgetProps["children"];
  stackColumnsAt?: Dimension<"px">;
}

export default SectionWidget;
